@import "https://fonts.googleapis.com/css2?family=Pacifico&display=swap";
@import "https://fonts.googleapis.com/css2?family=Space+Mono&display=swap";
.main {
  min-height: 500px;
}

#video-wrapper {
  position: relative;
}

#video-wrapper video {
  width: 100% !important;
  height: auto !important;
}

#video-wrapper #video-mini-controls {
  width: 70px;
  position: absolute;
  top: -5px;
  right: 5px;
}

#video-wrapper #video-state-badges {
  position: absolute;
  top: 8px;
  left: 20px;
}

#video-wrapper #video-buttons-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#footer {
  min-height: 100px;
}

@media (width >= 768px) {
  .container {
    max-width: 960px;
  }
}

.font-logo {
  font-family: Pacifico, cursive;
}

.font-mono {
  font-family: Space Mono, monospace;
}

#mic-check-wrapper {
  transition: transform .3s ease-in-out;
}

#mic-check-wrapper input[type="checkbox"] + label {
  background-color: var(--bs-gray);
}

#mic-check-wrapper input[type="checkbox"] + label .mic-active {
  display: none;
}

#mic-check-wrapper input[type="checkbox"] + label .mic-no-active {
  display: inline;
}

#mic-check-wrapper input[type="checkbox"]:checked + label {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

#mic-check-wrapper input[type="checkbox"]:checked + label .mic-active {
  display: inline;
}

#mic-check-wrapper input[type="checkbox"]:checked + label .mic-no-active {
  display: none;
}

#uploading-progress-block {
  pointer-events: none;
  width: 100%;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 12px;
}

#uploading-progress-block > div {
  pointer-events: auto;
  border: 1px solid var(--color-primary);
  background: var(--bs-tertiary-bg);
  padding: 10px;
}

:root {
  --color-primary: #94752a;
}

.progress {
  --bs-progress-bar-bg: var(--color-primary);
}

.accordion {
  --bs-accordion-active-bg: var(--color-primary);
  --bs-accordion-active-color: #090700;
  --bs-accordion-bg: var(--bs-tertiary-bg);
}

.accordion-button:after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%090700'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button {
  background-color: #414141;
}

.color-primary {
  color: var(--color-primary) !important;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  animation: 2s forwards fadeIn;
}

.blur-out {
  filter: blur();
}

.blur-in {
  animation: 2s forwards blurIn;
}

.fade-in-and-out {
  animation: 3s linear infinite fadeInOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes blurIn {
  from {
    filter: blur();
  }

  to {
    filter: blur(4px);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
}
/*# sourceMappingURL=index.fe028bf2.css.map */
